import React, { useContext, useMemo } from 'react'
import TrendingDestinations from 'home/components/TrendingDestinations/TrendingDestinations'
import Search from 'home/components/Search/Search'
import Pane from 'components/Common/Pane'
import HeroOfferCarouselSection from 'home/components/OfferCarousels/HeroOfferCarouselSection'
import HotelOfferCarousel from 'home/components/OfferCarousels/HotelOfferCarousel'
import TourOfferCarousel from 'home/components/OfferCarousels/TourOfferCarousel'
import config from 'constants/config'
import { rem } from 'polished'
import styled from 'styled-components'
import AnalyticsPageContext, { AnalyticsPage } from 'contexts/Analytics/analyticsPageContext'
import CruiseOfferCarousel from 'home/components/OfferCarousels/CruiseOfferCarousel'
import { isStandaloneCruiseEnabled } from 'selectors/cruiseOfferSelectors'
import { useAppSelector } from 'hooks/reduxHooks'
import SmartExperienceOfferCarousel from 'home/components/OfferCarousels/ExperienceOfferCarousel/SmartExperienceOfferCarousel'
import LayoutContainer from 'components/Common/LayoutContainer'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import { OfferListEventsProvider } from 'components/OfferList/OfferListEventsContext'
import useHomepageAnalytics from 'home/pages/HomePage/useHomepageAnalytics'
import { GlobalSearchTrackingProvider } from 'contexts/GlobalSearch/GlobalSearchTracking'
import { haveOrdersBeenFetched, sortOrdersByDeparture } from 'lib/order/orderUtils'
import { getUpcomingOrders } from 'selectors/orderSelectors'
import { upsellEnabledForOrder } from 'lib/order/upsellUtils'
import Group from 'components/utils/Group'
import OfferDetailUpsellSection from 'checkout/Components/Confirmation/BookingDetailsV2/OfferDetailUpsellSection'
import CompactBookingDetailsUpsellSection from 'checkout/Components/Confirmation/BookingDetailsV2/CompactBookingDetailsUpsellSection'
import { HOMEPAGE_FLIGHT_DEALS_REGIONS, NO_24_7_SUPPORT_REGIONS } from 'constants/config/region'
import GeoContext from 'contexts/geoContext'
import { InView } from 'react-intersection-observer'
import HomepageFlightDeals from 'home/components/FlightDeals/HomepageFlightDeals'
import { selectLoggedIn } from 'selectors/accountSelectors'
import FrontPageUSPComponent from 'components/Pages/FrontPage/USP/FrontPageUSPComponent'
import Divider from 'components/Luxkit/Divider'
import Trip from 'home/components/Trip'
import SupportTile from 'components/Common/SupportTile/SupportTile'
import ValuePropositionBanner from 'home/components/ValuePropositionBanner/ValuePropositionBanner'
import BrowseOfferTypes from 'home/components/BrowseOfferTypes/BrowseOfferTypes'
import MarketingCarousel from 'home/components/MarketingCarousel/MarketingCarousel'

const StyledLayoutContainer = styled(LayoutContainer)`
  padding-top: ${rem(40)};
`

function WhitelabelHomePage() {
  const { currentRegionCode } = useContext(GeoContext)

  const cruiseEnabled = useAppSelector(isStandaloneCruiseEnabled)
  const ordersFetched = useAppSelector((state) => haveOrdersBeenFetched(state, 'upcoming'))
  const upcomingOrders = useAppSelector((state) => getUpcomingOrders(state))
  const isLoggedIn = useAppSelector((state) => selectLoggedIn(state))

  const upsellOrder = useMemo(() => {
    return sortOrdersByDeparture(upcomingOrders)
      .find(upsellEnabledForOrder)
  }, [upcomingOrders])

  const showUpsellSection = ordersFetched && !!upsellOrder

  const { onHeroListEvent, onSearchEvent, onFlightListEvent, onTripOfferListEvent, onBestValueHotelListEvent, onCruiseListEvent, onHotelListEvent } = useHomepageAnalytics()

  const showFlightDeals = HOMEPAGE_FLIGHT_DEALS_REGIONS.includes(currentRegionCode)
  const showSupportContainer = !NO_24_7_SUPPORT_REGIONS.includes(currentRegionCode)

  return (
    <AnalyticsPageContext.Provider value={AnalyticsPage.homepage}>
      <Pane>
        <OfferListEventsProvider onListEvent={onHeroListEvent}>
          <HeroOfferCarouselSection />
        </OfferListEventsProvider>
        <GlobalSearchTrackingProvider onEvent={onSearchEvent}>
          <Search />
        </GlobalSearchTrackingProvider>
        {showUpsellSection &&
          <StyledLayoutContainer>
            <Group direction="vertical" gap={32}>
              <OfferDetailUpsellSection order={upsellOrder} />
              <CompactBookingDetailsUpsellSection order={upsellOrder} />
            </Group>
          </StyledLayoutContainer>
        }
        {!isLoggedIn && <StyledLayoutContainer>
          <VerticalSpacer gap={40}>
            <FrontPageUSPComponent />
            <Divider kind="primary" />
          </VerticalSpacer>
        </StyledLayoutContainer>}
        {isLoggedIn && !showUpsellSection && config.TRIP_PLANNER_ENABLE_HOMEPAGE_MODULE && (
          <OfferListEventsProvider onListEvent={onTripOfferListEvent}>
            <Trip />
          </OfferListEventsProvider>
        )}
        <TrendingDestinations />
        {showFlightDeals &&
          <OfferListEventsProvider onListEvent={onFlightListEvent}>
            <InView rootMargin="100px 0px 0px 0px" triggerOnce>
              <HomepageFlightDeals />
            </InView>
          </OfferListEventsProvider>
        }
        {config.EXPERIENCES_ENABLED && <SmartExperienceOfferCarousel />}
        <OfferListEventsProvider onListEvent={onBestValueHotelListEvent}>
          <HotelOfferCarousel sortBy="value" />
        </OfferListEventsProvider>
        <TourOfferCarousel title={<>Once-in-a-lifetime <i>tours</i></>} />
        {cruiseEnabled && (
          <OfferListEventsProvider onListEvent={onCruiseListEvent}>
            <CruiseOfferCarousel />
          </OfferListEventsProvider>
        )}
        {isLoggedIn && <OfferListEventsProvider onListEvent={onHotelListEvent}>
          <HotelOfferCarousel />
        </OfferListEventsProvider>}
        <MarketingCarousel />
        {showSupportContainer && <LayoutContainer>
          <SupportTile />
        </LayoutContainer>}
        <ValuePropositionBanner />
        <BrowseOfferTypes />
      </Pane>
    </AnalyticsPageContext.Provider>
  )
}

export default WhitelabelHomePage
